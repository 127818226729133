import axios from 'axios'
import authHeader from "./auth-header";

export class WagesRatesService {

    getWagesRates() {
        return axios.get(`/api/wage-rate-setting`, { headers: authHeader() })
            .then(res => res.data);
    }

    saveWagesRates(wageRate) {
        return axios.post('/api/wage-rate-setting', wageRate, { headers: authHeader() })
            .then(res => res.data);
    }

}