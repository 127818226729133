import {TabPanel, TabView} from "primereact/tabview";
import {SplitButton} from "primereact/splitbutton";
import {confirmDialog} from "primereact";
import nextId from "react-id-generator";
import {useRef, useState} from "react";
import {IncomeItemsModule} from "../modules/IncomeItemsModule";
import {isNullOrUndef} from "chart.js/helpers";
import {InvoicesService} from "../../../service/InvoicesService";
import {useHistory} from "react-router-dom";
import {Toast} from "primereact/toast";

export const IncomeTab = (props) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [invoiceTabKey, setInvoiceTabKey] = useState(1);
    const invoicesService = new InvoicesService();
    const JSum = require('jsum')
    let history = useHistory();
    let incomeToast = useRef(null);

    const emptyIncomeData = {
        client: '',
        creditTime: 30,
        currency: null,
        id: 'Id_' + nextId(),
        incomeItems: [],
        invoiceStatus: 'PENDING',
        issueDate: null,
        number: '',
        totalSum: null,
        version: 0
    }

    const confirmDelete = (income) => {
        confirmDialog({
            message: `Are you sure you want to remove this client ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteIncome(income),
            reject: () => null
        });
    }

    const deleteIncome = (income) => {
        let _incomes = [...props.booking.incomes]
        let filterIncomes = _incomes.filter(i => i.id !== income.id)
        props.onBookingChange(filterIncomes, 'incomes')
    }

    const label = (income) => {
        if (isNullOrUndef(income.client))
            return

        let color = income.invoiceStatus !== null && income.invoiceStatus === 'PENDING' ? '#FBC02D' : '#689F38'
        return <>
            <i className="pi pi-file-pdf" style={{fontWeight: "bold", color: color}}/> {income.client.title}
        </>
    }

    const checkAllServices = (income) => {
        return income.incomeItems.some(s => isNullOrUndef(s.quantity) || s.quantity === 0
                || isNullOrUndef(s.price) || s.price === 0 || isNullOrUndef(s.currency) || s.service.trim().length === 0)
            || isNullOrUndef(props.booking.portOfLoading) || props.booking.portOfLoading === ''
            || isNullOrUndef(props.booking.portOfDischarge) || props.booking.portOfDischarge === ''
    }

    const downloadInvoice = (invoiceData) => {
        invoicesService.getInvoicePdf(invoiceData.id).then(r => {
            if (r.status === 200) {
                const url = window.URL.createObjectURL(new Blob([r.data]));
                const link = document.createElement('a')
                link.href = url;
                link.setAttribute('download', invoiceData.number + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        })
    }

    const redirectToInvoice = (income) => {
        if (checkAllServices(income)) {
            props.toast.current.show({
                severity: 'error',
                summary: 'Edit Invoice',
                detail: 'All services must have Quantity, Real and Currency. POL, POD must be filed.'
            })
        } else if (JSum.digest(props.booking, 'SHA256', 'hex') !== props.checkSum) {
            props.toast.current.show({
                severity: 'warn',
                summary: 'Save booking',
                detail: 'Some data are changed! Please save booking first.'
            })
        } else {
            history.push(`/invoices/debit/view/${income.id}/${props.booking.id}/1`)
        }
    }


    const tabHeaderIIITemplate = (options, income) => {
        let items = [];
        if (income.incomeItems.length === 0) {
            items.push({label: 'Delete Client', icon: 'pi pi-trash', command: () => confirmDelete(income)})
        } else if (income.invoiceStatus !== null && income.invoiceStatus !== 'PENDING') {
            items.push(
                {
                    label: 'Download Invoice', icon: 'pi pi-download',
                    command: () => downloadInvoice(income)
                },
                {
                    label: 'Edit Invoice', icon: 'pi pi-file-edit',
                    disabled: props.isClosedIncomeCostTab || income.locked,
                    command: () => redirectToInvoice(income)

                }
            );
        } else {
            items.push({
                label: 'Create Invoice', icon: 'pi pi-refresh',
                disabled: props.isClosedIncomeCostTab || income.locked,
                command: () => redirectToInvoice(income)
            })
        }
        return (
            <SplitButton label={label(income)} model={items}
                         onClick={!props.activatedEdit ? options.onClick : props.warnAboutActiveEdit()}
                         className={'p-button-raised p-button-text mr-2 mb-2'}/>
        )
    };

    const addNewIncomeTemplate = (options) => {
        return <button type="button" disabled={props.isClosedIncomeCostTab}
                       onClick={() => addNewIncome(options)} className={options.className}>
            <i className="pi pi-plus mr-2"/>
            {options.titleElement}
        </button>
    }

    const addNewIncome = (options) => {
        if (!props.activatedEdit) {
            let _incomes = [...props.booking.incomes]
            _incomes.push(emptyIncomeData)
            props.onBookingChange(_incomes, 'incomes')
            setActiveIndex(options.index)
        } else {
            incomeToast.current.show({
                severity: 'warn',
                summary: 'Edit',
                detail: 'Some edit is activated! Close active edit. '
            });
        }
    }


    return <div className="card">
        <Toast ref={incomeToast}/>
        <div className="flex card-container">
            <div className="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-left h-4rem">
                <b>Plan</b>: <span title={'Plan'}><u>{props.booking.incomesPlan}</u></span> <span title={'initial Plan'}>({props.booking.incomesInitialPlan})</span> {props.defaultCurrency.code}
            </div>
            <div className="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-left h-4rem">
                <b>Real</b>: {props.booking.incomesReal} {props.defaultCurrency.code}
            </div>
        </div>
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            {props.booking.incomes.map(inc => {
                if (!isNullOrUndef(inc.client) && (isNullOrUndef(inc.creditTime) || inc.creditTime === '')) {
                    inc.creditTime = inc.client.ourCreditTime
                }

                return <TabPanel key={inc.id} headerTemplate={options => tabHeaderIIITemplate(options, inc)}
                                 headerClassName="flex align-items-center">
                    <IncomeItemsModule income={inc} onBookingChange={props.onBookingChange} toast={props.toast}
                                       setActivatedEdit={props.setActivatedEdit} checkUserRole={props.checkUserRole}
                                       warnAboutActiveEdit={props.warnAboutActiveEdit()} lockedInvoice={inc.locked}
                                       isClosedIncomeCostTab={props.isClosedIncomeCostTab} getClientGroupId={props.getClientGroupId}
                                       currencies={props.currencies} setInvoiceTabKey={setInvoiceTabKey}
                                       changeInvoiceData={props.changeInvoiceData} booking={props.booking}
                                       invoiceTabKey={invoiceTabKey} setBooking={props.setBooking}/>
                </TabPanel>
            })}
            <TabPanel key={'newInvoice'} header="Add new" onClick={(e) => console.log(e)}
                      headerTemplate={addNewIncomeTemplate}/>
        </TabView>
    </div>

}