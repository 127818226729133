import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import moment from "moment/moment";
import {BookingsService} from "../../service/BookingsService";
import {useMountEffect} from "primereact";
import {useHistory} from "react-router-dom";
import {Column} from "primereact/column";
import {isNullOrUndef} from "chart.js/helpers";
import {MultiSelect} from "primereact/multiselect";
import {Checkbox} from "primereact/checkbox";
import {confirmDialog} from "primereact/confirmdialog";
import {BookingFilters} from "./modules/BookingFilters";
import {ToggleButton} from "primereact/togglebutton";
import {LazySortMultiFields} from "../../components/LazySortMultiFields";

export const Bookings = (props) => {

    const [bookingsList, setBookingsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userAccess, setUserAccess] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loadFirstTime, setLoadFirstTime] = useState(true);
    const [filters, setFilters] = useState('');
    const [filter, setFilter] = useState(false);
    const [searchBooking, setSearchBooking] = useState(0);
    const [clearFilter, setClearFilter] = useState(0);
    const columns = [
        {key: 'manager', header: 'Manager', field: 'manager', width: '60px', order: 1},
        {key: 'operator', header: 'Operator', field: 'operator', width: '100px', order: 2},
        {key: 'nextOperator', header: 'Transferring to', field: 'nextOperator', width: '100px', order: 3},
        {key: 'operationalStatus', header: 'Operational Status', field: 'operationalStatus', width: '100px', order: 4},
        {key: 'tradeType', header: 'Imp/Exp', field: 'tradeType', width: '80px', order: 5},
        {key: 'status', header: 'Status', field: 'status', width: '80px', order: 6},
        {key: 'number', header: 'Booking Nr.', field: 'number', width: '110px', order: 7},
        {key: 'transportMode', header: 'Tr. Mode', field: 'transportMode', width: '110px', order: 8},
        {key: 'incoterm', header: 'Incotem', field: 'incoterm', width: '110px', order: 9},
        {key: 'shipper', header: 'Shipper', field: 'shipper.title', width: '200px', order: 10},
        {key: 'consignee', header: 'Consignee', field: 'consignee.title', width: '200px', order: 11},
        {key: 'direction', header: 'Trade', field: 'direction', width: '100px', order: 12},
        {key: 'forwarder', header: 'Forwarder', field: 'forwarder.title', width: '100px', order: 13},
        {key: 'shippingLine', header: 'Shipping Line', field: 'shippingLine', width: '100px', order: 14},
        {key: 'customerRefId', header: 'Customer ref', field: 'customerRefId', width: '100px', order: 15},
        {key: 'cargos.containerNumber', header: 'Container', field: 'cargos.containerNumber', width: '100px', order: 16},
        {key: 'bookingDate', header: 'Booking ID', field: 'bookingDate', width: '100px', order: 17},
        {key: 'cargos.dateOfLoading', header: 'LD/UD', field: 'cargos.dateOfLoading', width: '100px', order: 18},
        {key: 'cargos.placeOfDischargeArrival', header: 'PLD-ETA', field: 'cargos.placeOfDischargeArrival', width: '100px', order: 19},
        {key: 'cargos.lastFreeDay', header: 'LFD', field: 'cargos.lastFreeDay', width: '100px', order: 20},
        {key: 'placeOfLoading', header: 'PLL', field: 'placeOfLoading', width: '100px', order: 21},
        {key: 'portOfLoading', header: 'POL', field: 'portOfLoading', width: '100px', order: 22},
        {key: 'portOfDischarge', header: 'POD', field: 'portOfDischarge', width: '100px', order: 23},
        {key: 'placeOfDischarge', header: 'PLD', field: 'placeOfDischarge', width: '100px', order: 24},
        {key: 'portOfLoadingDeparture', header: 'POL-ETD', field: 'portOfLoadingDeparture', width: '100px', order: 25},
        {key: 'portOfDischargeArrival', header: 'POD-ETA', field: 'portOfDischargeArrival', width: '100px', order: 26},
        {key: 'portOfDischargeArrivalNotice', header: 'POD - ETA UPDATE', field: 'portOfDischargeArrivalNotice', width: '100px', order: 27},
        {key: 'cutOffDate', header: 'Cut Off', field: 'cutOffDate', width: '100px', order: 28},
        {key: 'cargoItems', header: 'Cargo', field: 'cargoItems', width: '100px', order: 29},
        {key: 'billOfLadingStatus', header: 'BL Status', field: 'billOfLadingStatus', width: '100px', order: 30},
        {key: 'importerSecurityFilling', header: 'ISF', field: 'importerSecurityFilling', width: '100px', order: 31},
        {key: 'remarks', header: 'Remarks', field: 'remarks', width: '100px', order: 32},
        {key: 'insurance', header: 'Insurance', field: 'insurance', width: '100px', order: 33},
        {key: 'customsClearance', header: 'CC', field: 'customsClearance', width: '100px', order: 34},
        {key: 'verifiedGrossMass', header: 'VGM', field: 'verifiedGrossMass', width: '100px', order: 35},
        {key: 'proofOfDelivery', header: 'POD', field: 'proofOfDelivery', width: '100px', order: 36},
        {key: 'shippingInstruction', header: 'SI', field: 'shippingInstruction', width: '100px', order: 37},
        {key: 'kipis', header: 'Kipis', field: 'kipis', width: '100px', order: 38},
        {key: 'pendingInvoice', header: 'Pending invoice', field: 'pendingInvoice', width: '100px', order: 39},
        {key: 'cargosLength', header: 'Containers count', field: 'cargosLength', width: '100px', order: 40},
    ];

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        multiSortMeta: [],
        filters: null
    });
    const [selectedColumns, setSelectedColumns] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const bookingsService = new BookingsService();
    const history = useHistory();
    let loadLazyTimeout = null;

    useMountEffect(() => {
        const columnsStorage = JSON.parse(window.localStorage.getItem('bookings-datatable-columns'));
        const filterToggleStorage = JSON.parse(window.localStorage.getItem('bookings-filter-toggle'));
        if (!isNullOrUndef(filterToggleStorage)) {
            setFilter(filterToggleStorage);
        }
        if (columnsStorage !== null) {
            setSelectedColumns(columnsStorage)
        } else {
            setSelectedColumns(columns)
            window.localStorage.setItem('bookings-datatable-columns', JSON.stringify(columns))
        }
        const dtStateLocal = JSON.parse(window.localStorage.getItem('booking-dt-state-local'));
        if (!isNullOrUndef(dtStateLocal)) {
            let _lazyParams = lazyParams;
            _lazyParams.rows = dtStateLocal.rows
            _lazyParams.first = dtStateLocal.first
            _lazyParams.page = dtStateLocal.first / dtStateLocal.rows
        }
        setLoading(true);
        getBookingList()
    })

    useEffect(() => {
        if (!loadFirstTime) {
            setLoading(true);

            if (loadLazyTimeout) {
                clearTimeout(loadLazyTimeout);
            }

            loadLazyTimeout = setTimeout(() => {
                getBookingList()
            }, 350);
        }
    }, [lazyParams, filters]);

    useEffect(() => {
        if (!isNullOrUndef(props.userData) && !isNullOrUndef(props.userData.authorities) && props.userData.authorities.length > 0) {
            setUserAccess(props.userData.authorities.some(a => a === 'READ_ALL_EVENTS'))
        }
    }, [props.userData]);

    const getBookingList = (exportToExcel) => {

        let filterParams = ''
        const filterParamsStorage = JSON.parse(window.localStorage.getItem('bookings-filter-data'));
        if (!isNullOrUndef(filters) && filters !== '') {
            filterParams = `&filter=${filters}`
            window.localStorage.setItem('bookings-filter-data', JSON.stringify(filters))
        } else if (!isNullOrUndef(filterParamsStorage)) {
            filterParams = `&filter=${filterParamsStorage}`
        }
        let sortFields = ''
        const sortParamsStorage = JSON.parse(window.localStorage.getItem('bookings-sort-data'));
        if (!isNullOrUndef(lazyParams.multiSortMeta) && lazyParams.multiSortMeta.length > 0) {
            lazyParams.multiSortMeta.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            window.localStorage.setItem('bookings-sort-data', JSON.stringify(lazyParams.multiSortMeta))
        } else if(!isNullOrUndef(sortParamsStorage)) {
            sortParamsStorage.forEach(s => {
                sortFields += s.field + ':' + s.order + ','
            })
            let _lazyParams = {...lazyParams}
            _lazyParams.multiSortMeta = sortParamsStorage
            setLazyParams(_lazyParams)
        }
        if (exportToExcel) {
            exportBookingToExcel(filterParams, sortFields)
        } else {
            getBookingsWithParams(filterParams, sortFields)
        }
    }

    const exportBookingToExcel = (filterParams, sortFields) => {
        let columns = selectedColumns.map(c => c.field).join(",");
        columns = 'id,' + columns;
        setLoading(true)
        bookingsService.exportToExcel('Booking', sortFields, filterParams, columns)
            .then(r => {
                if (r.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([r.data],
                        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a')
                    link.href = url;
                    link.setAttribute('download', 'Booking_' + moment(new Date()).format('YYYY-MM-DD_hh:mm:ss') + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                setLoading(false)
            })
    }

    const getBookingsWithParams = (filterParams, sortFields) => {
        bookingsService.getBookings(lazyParams.page, lazyParams.rows, sortFields, filterParams)
            .then(r => {
                setTotalRecords(r.data["totalElements"]);
                setBookingsList(r.data.content)
                setLoading(false);
                setLoadFirstTime(false)
            })
    }

    const editBooking = (bookingId) => {
        history.push(`/bookings/view/${bookingId}`);
    }

    const onFilterToggle = (e) => {
        setFilter(e.value)
        window.localStorage.setItem('bookings-filter-toggle', JSON.stringify(e.value))
    }

    const renderHeader = () => {
        return (<Fragment>
            <div className="table-header">
                <h5 className="p-mb-2">Bookings</h5>
            </div>
            <div className="flex justify-content-between flex-wrap card-container">
                <div className="flex align-items-center justify-content-center">
                    <MultiSelect value={selectedColumns} options={columns} maxSelectedLabels={0}
                                 placeholder="Select Item" id={'bookingList-selectColumns'} filter
                                 optionLabel={'header'} onChange={onColumnToggle} style={{width: '20em'}}/>
                    <ToggleButton checked={filter} onChange={onFilterToggle}
                                  onLabel="" offLabel="" className={'p-ml-3'}
                                  onIcon="pi pi-filter-slash" offIcon="pi pi-filter" aria-label="Filters"/>
                    <span className={'p-buttonset p-ml-3'}>
                            <Button icon="pi pi-search" iconPos="right"
                                    onClick={() => setSearchBooking(searchBooking + 1)}/>
                            <Button icon="pi pi-times" iconPos="right" onClick={() => setClearFilter(clearFilter + 1)}/>
                        </span>
                </div>
                <div className="flex align-items-center justify-content-center">
                    <Button icon="pi pi-plus" iconPos="right" label={'New'} className="p-button-success mr-2"
                            onClick={() => history.push("/bookings/add")}/>
                    <Button icon="pi pi-file-excel" iconPos="right" label={'Export'}
                            onClick={() => getBookingList(true)}/>
                </div>
            </div>
            <div>
                <BookingFilters loading={loading} selectedColumns={selectedColumns} filter={filter}
                                clientGroups={props.clientGroups} searchBooking={searchBooking}
                                clearFilter={clearFilter} typeLoad={'bookings'} getClientGroupId={props.getClientGroupId}
                                getBookings={getBookingList} filters={filters} setFilters={setFilters}/>
            </div>
        </Fragment>)
    }

    const copyBooking = (bookingId) => {
        bookingsService.copyBooking(bookingId).then(() => {
            getBookingList()
        })
    }

    const confirmCopy = (bookingId) => {
        confirmDialog({
            message: `Are you sure you want copy this [${bookingId}] booking ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => copyBooking(bookingId),
            reject: () => null
        });
    }

    const actionBodyTemplate = (rowData) => {
        return (<div className="actions" style={{textAlign: 'center'}}>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-text p-button-sm"
                    title={'Edit'} onClick={() => editBooking(rowData.id)}/>
            <Button icon="pi pi-copy" className="p-button-rounded p-button-warning p-button-text p-button-sm"
                    title={'Copy'} onClick={() => confirmCopy(rowData.id)}/>
        </div>);
    }

    const bookingDateTemplate = (rowData) => {
        if(isNullOrUndef(rowData.bookingDate))
            return

        return <div
            key={'bookingDate' + rowData.id}>{moment(rowData.bookingDate).format('YYYY-MM-DD')}</div>
    }

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
        window.localStorage.setItem('bookings-datatable-columns', JSON.stringify(selectedColumns))
    }

    const cargosFieldBody = (rowData) => {
        if (rowData.cargos.length === 0 || rowData.cargos.length === 0)
            return

        let contNumber = rowData.cargos.map(c => {
            return <div key={'contNumber-' + c.id}
                        id={'contNumber-' + c.id}>{c.containerNumber}({!isNullOrUndef(c.containerType) ? c.containerType.value : ''})</div>
        })

        return <div style={{display: "grid", textAlign: "center"}}>
            {contNumber}
        </div>
    }

    const cargosDatesBody = (rowData, key) => {
        if (rowData.cargos.length === 0)
            return

        let loadingDates = null
        if (key === 'cargos.dateOfLoading') {
            loadingDates = rowData.cargos.map(c => {
                if (!isNullOrUndef(c.dateOfLoading)) {
                    return <div key={'dateOfLoading-' + c.id}
                                id={'dateOfLoading-' + c.id}>{moment(c.dateOfLoading).format('YYYY-MM-DD')}</div>
                } else {
                    return <div key={'dateOfLoading-' + c.id} />
                }
            })
        } else if (key === 'cargos.placeOfDischargeArrival') {
            loadingDates = rowData.cargos.map(c => {
                if (!isNullOrUndef(c.placeOfDischargeArrival)) {
                    return <div key={'placeOfDischargeArrival-' + c.id}
                                id={'placeOfDischargeArrival-' + c.id}>{moment(c.placeOfDischargeArrival).format('YYYY-MM-DD')}</div>
                } else {
                    return <div key={'placeOfDischargeArrival-' + c.id}/>
                }
            })
        } else if (key === 'cargos.lastFreeDay') {
            loadingDates = rowData.cargos.map(c => {
                if (!isNullOrUndef(c.lastFreeDay)) {
                    return <div key={'lastFreeDay-' + c.id}
                                id={'lastFreeDay-' + c.id}>{moment(c.lastFreeDay).format('YYYY-MM-DD')}</div>
                } else {
                    return <div key={'lastFreeDay-' + c.id}/>
                }
            })
        }
        return <div style={{display: "grid", textAlign: "center"}}>
            {!isNullOrUndef(loadingDates) ? loadingDates : ''}
        </div>
    }

    const booleanItemsFieldBody = (rowData, field) => {
        let checked = !isNullOrUndef(rowData[field]) ? rowData[field] : false
        return <Checkbox inputId={rowData.id + '-' + field} style={{cursor: 'default'}} checked={checked}/>
    }

    const remarksFieldBody = (rowData, field) => {
        let remarks = ''
        if (!isNullOrUndef(rowData[field]) && rowData[field] !== '') {
            remarks = <i title={rowData[field]} style={{cursor: 'help'}} className={'pi pi-bookmark'}/>
        }

        return <div style={{textAlign: 'center', width: '100%'}}>
            {remarks}
        </div>
    }

    const cargosItemsFieldBody = (rowData) => {
        if (rowData.cargoItems.length === 0)
            return

        let cargoItems = rowData.cargoItems.map(c => {
            return <div id={'item-' + c.id} key={'item-' + c.id}>
                <i className={'pi pi-box'} style={{cursor: 'help'}} title={c.title}/>
            </div>
        })

        return <div style={{width: "100%", textAlign: "center"}}>
            {cargoItems}
        </div>
    }

    const managerColumnBody = (rowData) => {
        if(isNullOrUndef(rowData.manager))
            return

        return rowData.manager.firstName + ' ' + rowData.manager.lastName
    }

    const userBodyTemplate = (user) => {
        return user.firstName + ' ' + user.lastName
    }

    const statusBodyTemplate = (status) => {
        return status.name
    }

    const getBodyTemplate = (key, rowData) => {
        switch (key) {
            case 'manager':
                return managerColumnBody(rowData)
            case 'status':
            case 'tradeType':
            case 'transportMode':
            case 'portOfLoading':
            case 'portOfDischarge':
            case 'placeOfLoading':
            case 'placeOfDischarge':
                return !isNullOrUndef(rowData[key]) ? rowData[key].name : ''
            case 'incoterm':
            case 'shipper':
            case 'consignee':
            case 'shippingLine':
            case 'direction':
            case 'forwarder':
            case 'billOfLadingStatus':
            case 'importerSecurityFilling':
                return !isNullOrUndef(rowData[key]) ? rowData[key].title : ''
            case 'portOfLoadingDeparture':
            case 'portOfDischargeArrival':
            case 'portOfDischargeArrivalNotice':
            case 'cutOffDate':
                return !isNullOrUndef(rowData[key]) ? moment(rowData[key]).format('YYYY-MM-DD').toString() : ''
            case 'cargos.containerNumber':
                return !isNullOrUndef(rowData.cargos) ? cargosFieldBody(rowData) : ''
            case 'cargoItems':
                return !isNullOrUndef(rowData[key]) ? cargosItemsFieldBody(rowData) : ''
            case 'customsClearance':
            case 'verifiedGrossMass':
            case 'shippingInstruction':
            case 'kipis':
            case 'insurance':
            case 'proofOfDelivery':
                return booleanItemsFieldBody(rowData, key)
            case 'cargos.dateOfLoading':
            case 'cargos.placeOfDischargeArrival':
            case 'cargos.lastFreeDay':
                return !isNullOrUndef(rowData.cargos) ? cargosDatesBody(rowData, key) : ''
            case 'remarks':
                return remarksFieldBody(rowData, key)
            case 'bookingDate':
                return bookingDateTemplate(rowData)
            case 'operator':
            case 'nextOperator':
                return !isNullOrUndef(rowData[key]) ? userBodyTemplate(rowData[key]) : ''
            case 'cargosLength':
                return !isNullOrUndef(rowData.cargos) ? rowData.cargos.length : 0
            default:
                return !isNullOrUndef(rowData[key]) ? rowData[key] : ''
        }
    }

    const changeColumnSize = (e) => {
        const columnsStorage = JSON.parse(window.localStorage.getItem('bookings-datatable-columns'));
        columnsStorage.forEach(cs => {
            if(cs.field === e.column.props.field){
                cs.width = Number(cs.width.slice(0, -2)) + e.delta +'px'
            }
        });
        window.localStorage.setItem('bookings-datatable-columns', JSON.stringify(columnsStorage))
    }

    const columnComponents = () => {
        return selectedColumns.filter(c => c.key !== 'pendingInvoice').map((col, idx) => {
            // let colWidth = col.width;
            // const dtStateLocal = JSON.parse(window.localStorage.getItem('booking-dt-state-local'));
            // if(!isNullOrUndef(dtStateLocal)){
            //     let listWidths = dtStateLocal.columnWidths.replace(/, +/g, ",").split(",").map(Number);
            //     colWidth = listWidths[idx]
            // }
            return <Column style={{width: col.width}} headerStyle={{borderRight: '1px solid #e4e4e4'}} key={col.key} field={col.field} sortable={false}
                           header={<LazySortMultiFields header={col.header} fieldKey={col.key} crud={'bookings'}
                                                        field={col.field} lazyParams={lazyParams}
                                                        setLazyParams={setLazyParams}/>}
                           body={rowData => getBodyTemplate(col.key, rowData)}/>;
        });
    }

    // const onColReorder = (e) => {
    //     console.log(e)
    // let _selectedColumns = selectedColumns.filter(col => e.columns.some(sCol => sCol.props.field === col.props.field));
    // setSelectedColumns(_selectedColumns)
    // toast.current.show({severity: 'success', summary: 'Column Reordered', life: 3000});
    // }


    return (
        <div className="p-grid" style={{margin: '0 -20px'}}>
            <div className="p-col-12">
                <Toast ref={toast}/>
                <div style={{height: 'calc(100vh - 115px)', fontSize: '15px'}}>
                <DataTable ref={dt}
                           value={bookingsList}
                           dataKey="id"
                           totalRecords={totalRecords}
                           onPage={(e) => setLazyParams(e)}
                           header={renderHeader}
                           paginator
                           onColumnResizeEnd={changeColumnSize}
                           rows={lazyParams.rows}
                           rowHover lazy
                           loading={loading}
                           rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
                           first={lazyParams.first}
                           id={'bookings-list'}
                           size="small"
                           // loading={loading}
                           // responsiveLayout="scroll"
                           scrollable
                           scrollHeight="flex"
                           resizableColumns
                           columnResizeMode="expand"
                           stateStorage="local"
                           stateKey="booking-dt-state-local"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                           emptyMessage="No bookings.">
                    <Column header={'Ref.ID'} key={'id'} field={'id'} resizeable={true} headerStyle={{borderRight: '1px solid #e4e4e4'}} frozen alignFrozen={'left'}
                            style={{width: '60px', background: 'white'}}/>
                    {columnComponents()}
                    <Column header={'Actions'} key={'actions'} resizeable={true} headerStyle={{borderLeft: '1px solid #e4e4e4'}} body={actionBodyTemplate} frozen alignFrozen={'right'}
                            style={{width: '85px', background: 'white'}}/>
                </DataTable>
                </div>
            </div>
        </div>
    );

}