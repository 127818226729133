import React, {useContext, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import { MegaMenu } from 'primereact/megamenu';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { RTLContext } from './App';
import {EventService} from "./service/EventService";
import AuthService from "./service/AuthService";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import moment from "moment";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";
import {confirmDialog} from "primereact/confirmdialog";
import {CurrencyService} from "./service/CurrencyService";
import {isNullOrUndef} from "chart.js/helpers";
const eventService = new EventService();

const AppTopbar = (props) => {

    const isRTL = useContext(RTLContext);
    const history = useHistory();
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState([]);
    const [eventDialog, setEventDialog] = useState(false);
    const [eventBadge, setEventBadge] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [eventStatuses, setEventStatuses] = useState([]);
    const [todayExRateUSD, setTodayExRateUSD] = useState('');
    const [todayExRateGBP, setTodayExRateGBP] = useState('');
    const toast = useRef(null);
    const currencyService = new CurrencyService();

    useEffect(() => {
        if(props.userData !== null) {
            eventService.getUnreadUserEvents(props.userData.id).then(eventsData => {
                if (eventsData.length > 0) {
                    setEvents(eventsData)
                    setEventBadge(true)
                }
            });
            eventService.getEventStatuses().then(statuses => {
                setEventStatuses(statuses)
            })
        }
    }, [props.userData])

    useEffect(() => {
        if(!isNullOrUndef(props.currencies)){
            props.currencies.forEach(c => {
                if(!c.standard && c.code === 'USD'){
                    currencyService.getTodayExchangeRateByCurr(c.id).then(cr => setTodayExRateUSD(cr.rate))
                } else  if(!c.standard && c.code === 'GBP'){
                    currencyService.getTodayExchangeRateByCurr(c.id).then(cr => setTodayExRateGBP(cr.rate))
                }
            })
        }
    }, [props.currencies])

    useEffect(() => {
        if(props.userData !== null) {
            eventService.getUnreadUserEvents(props.userData.id).then(eventsData => {
                if (eventsData.length > 0) {
                    setEvents(eventsData)
                    setEventBadge(true)
                } else {
                    setEventBadge(false)
                }
            });
        }
    }, [props.event, props.userData])

    // Fixed for 6.1.0
    // eslint-disable-next-line
    const searchPanel = useRef(null)

    useEffect(() => {
        // Fixed for 6.1.0
        /*if (props.searchActive) {
            searchPanel.current.element.focus();
        }*/
    }, [props.searchActive])

    const model = [];

    const clickEventRead = (event) => {
        setEvent(event)
        setEventDialog(true)
    }

    const hideEventDialog = () => {
        setEventDialog(false);
    }

    const setEventRead = () => {
        let _event = {
            id: event.id,
            clientId: event.client.id,
            contactPersonId: event.contactPerson !== null ? event.contactPerson.id : null,
            eventTypeId: event.eventType.id,
            userIDs:  event.users.map(u => u.id),
            date: event.date,
            notes: event.notes,
            remainder: event.remainder,
            doneDateTime: new Date(),
            read: true,
            eventStatusId: event.eventStatus,
            resolution: event.resolution
        }

        eventService.setEvent(_event).then(data => {
            const index = findIndexById(event.id);
            events.splice(index, 1);
            if(events.length === 0){
                setEventBadge(false)
            }
            setEventDialog(false)
            setSubmitted(true)
            toast.current.show({
                severity: 'success',
                summary: 'Successful',
                detail: 'Event set read',
                life: 3000
            });
        });
    }

    const eventDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" style={{color: '#3F51B5'}} onClick={hideEventDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" style={{color: '#3F51B5'}} onClick={setEventRead}/>
        </>
    );

    const onResolutionChange = (e) => {
        let _event = {...event}
        _event.resolution = e.target.value
        setEvent(_event)
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < events.length; i++) {
            if (events[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const logout = () => {
        AuthService.logout(history);
    }

    const confirmReset = () => {
        confirmDialog({
            message: `Are you sure you want reset this list settings(filter, sort and etc.) ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => props.resetStorage(),
            reject: () => null
        });
    }

    const changeEventStatus = (e) => {
        let _event = {...event}
        _event.eventStatus = e.target.value.id
        setEvent(_event)
    }

    const eventStatusValue = (status) => {
        return eventStatuses.find(s => s.id === status)
    }

    return (
        <div className="layout-topbar p-shadow-4">
            <div className="layout-topbar-left">
                <button type="button" style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => history.push('/')}>
                    <img id="app-logo" src="assets/layout/images/logo-light.svg" alt="ultima-layout" style={{ height: '2.25rem' }} />
                </button>
                <button type="button" className="layout-menu-button p-shadow-6 p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-right"/>
                </button>
                <button type="button" className="layout-topbar-mobile-button p-link">
                    <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopbarButtonClick}/>
                </button>
                <div style={{
                    background: "#3c8dbc",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginLeft: "30px",
                    paddingLeft: "15px"
                }}>
                    <span><i className={'pi pi-calendar'}/> Today: {moment().format('YYYY MMM DD')}</span>
                </div>
                <div style={{
                    background: "#3c8dbc",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingLeft: "20px"
                }}>
                    <div>{todayExRateUSD} USD</div>
                    <div>{todayExRateGBP} GBP</div>
                </div>
            </div>

            <div className={classNames('layout-topbar-right', { 'layout-topbar-mobile-active': props.mobileTopbarActive })}>
                <div className="layout-topbar-actions-left">
                    <MegaMenu model={model} className="layout-megamenu" />
                </div>
                <div className="layout-topbar-actions-right">
                    <ul className="layout-topbar-items">
                        <li className="layout-topbar-item notifications">
                            <button className="layout-topbar-action rounded-circle p-link" title={'My events'} onClick={() => history.push('/my-events')}>
                                <span className="p-overlay-badge">
                                    <i className="pi pi-calendar fs-large" />
                                </span>
                            </button>
                            <button className="layout-topbar-action rounded-circle p-link" title={'Notifications'} onClick={(e) => props.onTopbarItemClick({ originalEvent: e, item: 'notifications' })}>
                                <span className="p-overlay-badge">
                                    <i className="pi pi-bell fs-large" />
                                    <span className={classNames({'p-badge p-badge-warning p-badge-dot': eventBadge })}/>
                                </span>
                            </button>

                            <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'notifications'} unmountOnExit>
                                <ul className="layout-topbar-action-panel p-shadow-6 fadeInDown">
                                    <li className="p-mb-3">
                                        <span className="p-px-3 fs-small">You have <b>{events.length}</b> new notifications</span>
                                    </li>
                                    <div style={{overflowY: 'auto', maxHeight: '70vh'}}>
                                    {events.length > 0 ? events.map(event => {
                                        return (<li className="layout-topbar-action-item" key={event.id} onClick={() => clickEventRead(event)}>
                                            <div className="p-d-flex p-flex-row p-ai-center">
                                                <div>
                                                    <Button icon="pi pi-bookmark" className="p-button-rounded p-button-warning p-mr-2 p-mb-2" />
                                                </div>
                                                <div className={'p-d-flex p-flex-column p-mr-3'} style={{flexGrow: '1'}}>
                                                    <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                                                        <span className="p-text-bold">{event.client.title}</span>
                                                        <span className="fs-small">Type: {event.eventType.title}</span>
                                                    </div>
                                                    <span className="fs-small">{event.notes}</span>
                                                    <small className="p-ml-auto">{moment(event.date).format('YYYY-MM-DD HH:MM')}</small>
                                                </div>
                                            </div>
                                        </li>)
                                    }) : ''}
                                    </div>
                                </ul>
                            </CSSTransition>
                        </li>
                        <li className="layout-topbar-item">
                            <button className="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2 rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'profile' })}>
                                <i className="pi pi-user fs-large" />
                            </button>

                            <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                                <ul className="layout-topbar-action-panel p-shadow-6">
                                    {/*<li className="layout-topbar-action-item">*/}
                                    {/*    <button className="p-d-flex p-flex-row p-ai-center p-link">*/}
                                    {/*        <i className={classNames('pi pi-cog', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>*/}
                                    {/*        <span>Settings</span>*/}
                                    {/*    </button>*/}
                                    {/*</li>*/}
                                    <li className="layout-topbar-action-item" onClick={() => confirmReset('clients')}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-refresh', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Reset Clients settings</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => confirmReset('bookings')}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-refresh', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Reset Booking settings</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => confirmReset('debit')}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-refresh', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Reset Debit settings</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => confirmReset('credit')}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-refresh', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Reset Credit settings</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => confirmReset('payments')}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-refresh', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Reset Payments settings</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => confirmReset('purchase')}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-refresh', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Reset Purchase settings</span>
                                        </button>
                                    </li>
                                    {props.checkUserRole(['ROLE_ADMIN', 'READ_REPORT_COSTS']) ?
                                    <li className="layout-topbar-action-item" onClick={() => confirmReset('costs-report')}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-refresh', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Reset Kaupimai Report settings</span>
                                        </button>
                                    </li>: ''}
                                    {props.checkUserRole(['ROLE_ADMIN', 'READ_REPORT_PLAN_REAL']) ?
                                    <li className="layout-topbar-action-item" onClick={() => confirmReset('bookingsOperations')}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-refresh', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Reset Bookings Operations settings</span>
                                        </button>
                                    </li>: ''}
                                    {props.checkUserRole(['ROLE_ADMIN', 'READ_REPORT_PLAN_REAL']) ?
                                    <li className="layout-topbar-action-item" onClick={() => confirmReset('planReal')}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-refresh', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Reset Plan/Real settings</span>
                                        </button>
                                    </li> : ''}
                                    <li className="layout-topbar-action-item" onClick={() => confirmReset('wages')}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-refresh', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Plan/Real (New) settings</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => confirmReset('all')}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-refresh', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Reset All settings</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={logout}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-power-off', {'p-mr-2': !isRTL, 'p-ml-2': isRTL})}/>
                                            <span>Logout</span>
                                        </button>
                                    </li>
                                </ul>
                            </CSSTransition>
                        </li>
                    </ul>
                </div>
            </div>
            <Dialog visible={eventDialog} style={{width: '300px'}} header="Set read event" modal
                    footer={eventDialogFooter} onHide={hideEventDialog}>
                <div className="confirmation-content p-fluid">
                    <div className="p-field">
                        <label htmlFor="status">Status</label>
                        <Dropdown value={eventStatusValue(event.eventStatus)} onChange={changeEventStatus}
                                     options={eventStatuses} panelHeaderTemplate={null}
                                     optionLabel={'name'} placeholder="-- Select --"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="resolution">Resolution</label>
                        <InputTextarea id="resolution" value={event.resolution !== null ? event.resolution : ''} onChange={onResolutionChange}
                                       required autoFocus autoResize rows={5} cols={30} placeholder="Write a resolution..."
                                       className={classNames({'p-invalid': submitted && !event.resolution})}/>
                    </div>
                </div>
            </Dialog>
            <Toast ref={toast}/>
        </div>
    );
}

export default AppTopbar;
