import React, {createRef, useRef, useState} from "react";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import moment from "moment";
import nextId from "react-id-generator";
import {isNullOrUndef} from "chart.js/helpers";
import {Checkbox} from "primereact/checkbox";
import {confirmDialog} from "primereact";
import {onViewChange, todayCalendar} from "../../../App";

export const CargosModule = (props) => {

    const [editingRows, setEditingRows] = useState({});
    const [editingRowId, setEditingRowId] = useState(null);
    const [cargosKey, setCargosKey] = useState(1);
    let calendarRef = useRef(null);

    const addNewContainer = () => {
        if (isNullOrUndef(editingRowId) && !props.activatedEdit) {
            let _cargos = [...props.booking.cargos]
            let newTempId = nextId()
            _cargos.push({
                id: newTempId,
                bookingId: props.booking.id,
                backload: false,
                containerType: null,
                containerNumber: '',
                seal: '',
                clientRefId: '',
                dateOfLoading: null,
                placeOfDischargeArrival: null,
                lastFreeDay: null,
                gateOut: null,
                gateIn: null,
            })
            setActiveRowIndex(newTempId)
            props.booking.cargos = _cargos
            setCargosKey(cargosKey + 1)
        } else {
            props.warnAboutActiveEdit()
        }
    }

    const deleteContainer = (cargoId) => {
        let _cargos = [...props.booking.cargos]
        _cargos = _cargos.filter(c => c.id !== cargoId)
        props.booking.cargos = _cargos
        props.setActivatedEdit(false)
        setEditingRows({})
        setEditingRowId(null)
        setCargosKey(cargosKey + 1)
    }

    const confirmDelete = (containerId, containerNumber) => {
        confirmDialog({
            message: `Are you sure you want to remove this [${containerNumber}] container?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteContainer(containerId),
            reject: () => null
        });
    }

    const setActiveRowIndex = (itemId) => {
        let _editingRows = {...editingRows, ...{[`${itemId}`]: true}};
        props.setActivatedEdit(true)
        setEditingRows(_editingRows);
        setEditingRowId(itemId)
    }

    const onCellChange = (value, options) => {
        let {column, rowIndex, rowData} = options;

        if (column.props.columnKey === 'containerNumber' && !isNullOrUndef(value) && value.length > 11) {
            props.toast.current.show({
                severity: 'error',
                summary: 'Container number',
                detail: 'Container number max length is 11! '
            });
        } else {
            let _cargos = [...props.booking.cargos]
            if (column.props.columnKey === 'containerType') {
                rowData[column.props.columnKey] = props.containerTypes.find(c => c.id === value);
            } else {
                rowData[column.props.columnKey] = value;
            }

            _cargos[rowIndex] = rowData;
            props.booking.cargos = _cargos
            setCargosKey(cargosKey + 1)
        }
    }

    const selectedContTypesTemplate = (option, props) => {
        if (!isNullOrUndef(props.value) && props.value !== "") {
            return props.value.value
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const textEditor = (options) => {
        return <InputText type="text" value={!isNullOrUndef(options.value) ? options.value : ''}
                          onChange={(e) => onCellChange(e.target.value, options)}/>;
    }

    const checkBoxEditor = (options) => {
        return <Checkbox checked={options.value}
                         onChange={(e) => onCellChange(e.checked, options)}/>
    }

    const dateEditor = (options) => {
        return <Calendar showIcon dateFormat={'yy-mm-dd'} locale={'lt'}
                         viewDate={todayCalendar(options.value)} ref={calendarRef}
                         onViewDateChange={(e) => onViewChange(e, calendarRef)} numberOfMonths={3}
                         value={!isNullOrUndef(options.value) ? moment(options.value).toDate() : null}
                         onChange={(e) => onCellChange(e.value, options)}/>
    }

    const dropDownEditor = (options) => {
        return <Dropdown value={options.value} options={props.containerTypes} optionValue={'id'} optionLabel={'value'}
                         valueTemplate={selectedContTypesTemplate} placeholder="-- Select --"
                         onChange={(e) => onCellChange(e.value, options)}/>
    }

    const saveCargoRow = (data) => {
        if (data.containerNumber === '' && isNullOrUndef(data.containerType) && data.clientRefId === '' && isNullOrUndef(data.clientRefId)
            && isNullOrUndef(data.dateOfLoading) && isNullOrUndef(data.gateIn) && isNullOrUndef(data.gateOut)
            && isNullOrUndef(data.lastFreeDay) && isNullOrUndef(data.placeOfDischargeArrival)) {
            props.toast.current.show({severity: 'error', summary: 'Container', detail: 'Cannot be saved, all fields are empty! '});
        } else {
            setEditingRows({})
            setEditingRowId(null)
            props.setActivatedEdit(false)
        }
    }

    const actionTemplate = (data) => {
        return <div key={data.id} className="actions">
            {!isNullOrUndef(editingRowId) && editingRowId === data.id ?
                <Button icon="pi pi-check" className="p-button-rounded p-button-text p-button-sm"
                        title={'Save'} onClick={() => saveCargoRow(data)}/>
                :
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-sm"
                        title={'Edit'} disabled={props.isClosed}
                        onClick={() => isNullOrUndef(editingRowId) && !props.activatedEdit ? setActiveRowIndex(data.id) : props.warnAboutActiveEdit()}/>
            }
            <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-sm"
                    disabled={props.isClosed}
                    title={'Delete'} onClick={() => confirmDelete(data.id, data.containerNumber)}/>
        </div>
    }

    const onRowEditChange = (e) => {

    }

    const onRowEditComplete = (e) => {

    }

    const contTypeBodyTemplate = (data) => {
        if (isNullOrUndef(data.containerType) || data.containerType === "")
            return

        return data.containerType.value
    }

    const headerTemplate = () => {
        return <Button icon="pi pi-plus" className="p-button-rounded p-button-text p-button-sm"
                       disabled={props.isClosed}
                       title={'Add New'} onClick={() => addNewContainer()}/>
    }

    const dateBodyTemplate = (data, type) => {
        if (isNullOrUndef(data[type]) || data[type] === '')
            return

        return <div>
            {moment(data[type]).format('YYYY-MM-DD')}
        </div>
    }

    const backLoadBodyTemplate = (rowData) => {
        let check = !isNullOrUndef(rowData.backload) ? rowData.backload : false;
        return <Checkbox checked={check} style={{margin: '10px 5px'}}/>
    }

    const rowClassNameTemplate = (data) => {
        if(data.id === editingRowId) {
            return 'boldClassRowEdit'
        }
    }

    const containerTableFooter = () => {
        return <Button icon="pi pi-plus" className="p-button-rounded p-button-text p-button-sm"
                       disabled={props.isClosed} style={{width: '100%', borderRadius: '5%'}}
                       title={'Add New Cargo'} onClick={() => addNewContainer()}/>
    }

    return <DataTable value={props.booking.cargos} showGridlines
                      responsiveLayout="scroll" size="small"
                      editMode="row" rowClassName={rowClassNameTemplate}
                      dataKey="id" editingRows={editingRows}
                      footer={containerTableFooter}
                      onRowEditChange={onRowEditChange} sortField={'id'}
                      onRowEditComplete={onRowEditComplete}>
        <Column columnKey={'containerType'} style={{width: '150px'}} field={'containerType'} header={'Container Type'}
                body={contTypeBodyTemplate} editor={(options) => dropDownEditor(options)}/>

        <Column columnKey={'containerNumber'} style={{width: '130px'}} field={'containerNumber'}
                header={'Container number'} editor={(options) => textEditor(options)}/>

        <Column columnKey={'seal'} style={{width: '130px'}} field={'seal'}
                header={'Seal'} editor={(options) => textEditor(options)}/>

        <Column columnKey={'clientRefId'} style={{width: '130px'}} field={'clientRefId'}
                header={'Customer Ref.'} editor={(options) => textEditor(options)}/>

        <Column columnKey={'dateOfLoading'} field={'dateOfLoading'} header={'LD/UD'}
                body={data => dateBodyTemplate(data, 'dateOfLoading')}
                editor={(options) => dateEditor(options)}/>

        <Column columnKey={'placeOfDischargeArrival'} field={'placeOfDischargeArrival'} header={'PLD - ETA'}
                body={data => dateBodyTemplate(data, 'placeOfDischargeArrival')}
                editor={(options) => dateEditor(options)}/>

        <Column columnKey={'lastFreeDay'} field={'lastFreeDay'} header={'LFD'}
                body={data => dateBodyTemplate(data, 'lastFreeDay')}
                editor={(options) => dateEditor(options)}/>

        <Column columnKey={'gateOut'} field={'gateOut'} header={'Gate out'}
                body={data => dateBodyTemplate(data, 'gateOut')}
                editor={(options) => dateEditor(options)}/>

        <Column columnKey={'gateIn'} field={'gateIn'} header={'Gate in'}
                body={data => dateBodyTemplate(data, 'gateIn')}
                editor={(options) => dateEditor(options)}/>

        <Column columnKey={'backload'} field={'backload'} header={'Backload'} body={backLoadBodyTemplate}
                editor={(options) => checkBoxEditor(options)}/>

        <Column columnKey={'action'} headerClassName={'center'} header={headerTemplate} style={{width: '90px'}}
                body={actionTemplate}/>
    </DataTable>
}
