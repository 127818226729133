import React, {useState, useRef} from 'react';
import {InvoicesService} from "../../service/InvoicesService";
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {isNullOrUndef} from "chart.js/helpers";
import moment from "moment";
import {useMountEffect} from "primereact/hooks";
import {ExportFileService} from "../../service/ExportFileService";
import {Calendar} from "primereact/calendar";
import {AutoComplete} from "primereact/autocomplete";
import {ClientService} from "../../service/ClientService";
import {InputText} from "primereact/inputtext";
import {calendarFooterTemplate, todayCalendar} from "../../App";

export const ExportToNavision = (props) => {

    const [invoicesList, setInvoicesList] = useState([])
    const [loading, setLoading] = useState(true);
    const [dateFrom, setDateFrom] = useState(moment().startOf('month'));
    const [dateTo, setDateTo] = useState(moment().endOf('month'));
    const [client, setClient] = useState(null);
    const [filteredClients, setFilteredClients] = useState([]);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const invoicesService = new InvoicesService();
    const toast = useRef(null);
    const dt = useRef(null);
    const exportFileService = new ExportFileService();
    const clientService = new ClientService();
    let calendarRef = useRef(null);
    let calendarInputRef = useRef(null);
    useMountEffect(() => {
        setLoading(true);
        getInvoices()
    })
    const getInvoices = () => {
        let clientId = !isNullOrUndef(client) ? client.id : null
        if(!isNullOrUndef(dateFrom) && !isNullOrUndef(dateTo)) {
            invoicesService.getInvoicesForExport(clientId, moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD'), invoiceNumber)
                .then(data => {
                    setInvoicesList(data);
                    setLoading(false);
                }).catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
            });
        } else {
            setLoading(false)
            toast.current.show({severity: 'error', summary: 'Error', detail: 'Wrong date range', life: 3000});
        }
    }

    const changeDateIssue = (e) => {
        setDateFrom(e.target.value[0])
        setDateTo(e.target.value[1])
    }

    const getDateValues = () => {
        let dateValue = [
            !isNullOrUndef(dateFrom) ? moment(dateFrom).toDate() : null,
            !isNullOrUndef(dateTo) ? moment(dateTo).toDate() : null,
        ]
        return dateValue
    }

    const searchClient = (event) => {
        if (event.query.trim().length > 2) {
            clientService.searchClientsByGroupId(event.query.trim().toLowerCase(), props.getClientGroupId('customer')).then(data => {
                setFilteredClients(data)
            });
        }
    }

    const header = <>
        <div className="table-header p-mb-2">
            <h5 className="p-m-0">Export to Navision</h5>
        </div>
        <div className="flex justify-content-between flex-wrap card-container">
            <div className="flex align-items-center justify-content-center">
            <span className="p-float-label">
            <Calendar id={`filter-date-issue`} selectionMode="range" inputRef={calendarInputRef}
                      viewDate={todayCalendar(getDateValues()[0])} numberOfMonths={3} ref={calendarRef}
                      footerTemplate={() => calendarFooterTemplate(calendarRef, calendarInputRef)}
                      dateFormat={'yy-mm-dd'} locale={'lt'}
                      value={getDateValues()} onChange={changeDateIssue}/>
            <label htmlFor={`filter-date-issue`}>Issue date</label>
            </span>
                <span className="p-float-label">
            <AutoComplete id={`filter-client`} value={client} showEmptyMessage
                          suggestions={filteredClients} completeMethod={searchClient} minLength={3}
                          tooltip={'Min 3 characters'} field={'title'} style={{marginLeft: '10px'}}
                          onChange={(e) => setClient(e.target.value)}/>
            <label htmlFor={`filter-client`}>Customer</label>
            </span>
                <span className="p-float-label">
            <InputText id={`filter-invoice-number`}
                       value={invoiceNumber}
                       className={'block'} style={{marginLeft: '10px'}}
                       onChange={(e) => setInvoiceNumber(e.target.value)}/>
            <label htmlFor={`filter-invoice-number`}>Invoice number</label>
            </span>
                <Button icon="pi pi-search" iconPos="right" style={{marginLeft: '10px'}} onClick={() => {
                    setLoading(true);
                    getInvoices();
                }}/>
            </div>
            <div className="flex align-items-center justify-content-center">
                <Button icon="pi pi-file-export" iconPos="right" label={'Export CSV'}
                        onClick={() => exportFileService.exportExcel(invoicesList, 'Navision', toast)}/>
                <Button icon="pi pi-file-export" iconPos="right" label={'Export XML'} style={{marginLeft: '10px'}}
                        onClick={() => exportFileService.exportXML(!isNullOrUndef(client) ? client.id : null, moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD'), invoiceNumber, toast)}/>
            </div>
        </div>
    </>;

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                    <Toast ref={toast}/>
                    <DataTable ref={dt} value={invoicesList}
                               dataKey={'uniqueNumber'} paginator rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
                               className="datatable-responsive" loading={loading} rowHover rows={10}  size="small"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} invoices"
                               emptyMessage="No Invoices found." header={header}>
                        <Column field={'dateOfIssue'} header="Issue date" style={{minWidth: '90px'}} />
                        <Column field={'invoiceNumber'} header="Invoice Nr." />
                        <Column field={'navisionCode'} header="Navision DK" />
                        <Column field={'currency'} header="Currency" />
                        <Column field={'uniqueNumber'} header="Unique Nr." />
                        <Column field={'sum'} header="Sum" />
                        <Column field={'vatSum'} header="Vat" />
                        <Column field={'sumWithVat'} header="Total" />
                        <Column field={'clientCode'} header="Company code" />
                        <Column field={'clientVatCode'} header="VAT code" />
                        <Column field={'clientName'} header="Customer" />
                        <Column field={'clientAddress'} header="Address" />
                        <Column field={'clientCity'} header="City" />
                        <Column field={'clientCountry'} header="Country" />
                    </DataTable>
            </div>
        </div>
    );

}