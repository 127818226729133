import React, {useEffect, useRef, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {Dialog} from "primereact/dialog";
import classNames from "classnames";
import {EventService} from "../service/EventService";
import {UserService} from "../service/UserService";
import {Dropdown} from "primereact/dropdown";
import {ClientService} from "../service/ClientService";
import {Calendar} from 'primereact/calendar';
import {InputTextarea} from "primereact/inputtextarea";
import {InputNumber} from "primereact/inputnumber";
import moment from "moment-timezone";
import {MultiSelect} from "primereact/multiselect";
import {onViewChange, todayCalendar} from "../App";

export const Events = (props) => {

    let emptyEvent = {
        id: null,
        client: props.client.id,
        contactPerson: null,
        eventType: null,
        date: null,
        notes: '',
        remainder: null,
        read: false,
        doneDateTime: null,
        reminded: false,
        users: null,
        eventStatus: null,
        resolution: ''
    };

    const [events, setEvents] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventDialog, setEventDialog] = useState(false);
    const [deleteEventDialog, setDeleteEventDialog] = useState(false);
    const [event, setEvent] = useState(emptyEvent);
    const [eventStatuses, setEventStatuses] = useState([]);
    const [eventsTypes, setEventsTypes] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [clientContactPersons, setClientContactPersons] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [users, setUsers] = useState(null);
    const toast = useRef(null);
    const eventService = new EventService();
    const clientService = new ClientService();
    const userService = new UserService();
    let calendarRef = useRef(null);

    useEffect(() => {
        if(props.userData){
            let _admin = props.userData.authorities.some(u => u === 'ROLE_ADMIN')
            setAdmin(_admin);
            eventService.getEventStatuses().then(data => {
                setEventStatuses(data)
            })
            userService.getManagers().then(data => {
                setUsers(data)
                clientService.getClientContactPersons(props.client.id).then(data => {
                    setClientContactPersons(data);
                    eventService.getEventTypes().then(data => {
                        setEventsTypes(data);
                        eventService.getEvents(props.client.id).then(data => {
                            setEvents(data);
                            setLoading(false)
                        });
                    });
                });
            });
         }
    }, [props.client, props.userData]);

    const confirmDeleteEvent = (event) => {
        setEvent(event);
        setDeleteEventDialog(true);
    }

    const openNew = () => {
        let _emptyEvent = {...emptyEvent}
        if (!admin) {
            _emptyEvent.user = props.userData.id;
        }
        setEvent(_emptyEvent);
        setSubmitted(false);
        setEventDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEventDialog(false);
    }

    const hideDeleteEventDialog = () => {
        setDeleteEventDialog(false);
    }

    const editEvent = (event) => {
        setEvent({...event});
        setEventDialog(true);
    }

    const checkRequired = (type) => {
        setSubmitted(true);
        let _submitted = true;
        if ((event.resolution === null || event.resolution === '' || event.eventStatus === null) && event.reminded) {
            _submitted = false;
        }

        if ((event.eventType === null || event.users === null ||
            event.date === null || event.remainder === null) && !event.reminded) {
            _submitted = false;
        }

        if (_submitted) {
            saveEvent(type);
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Requirement',
                detail: 'Please fill all Required fields.',
                life: 3000
            });
        }
    }

    const saveEvent = (type) => {
        setSubmitted(true);

        let _events = [...events];
        let _event = {
            id: event.id,
            clientId: typeof event.client === 'object' ? event.client.id : event.client,
            contactPersonId: event.contactPerson !== null ? event.contactPerson.id : null,
            eventTypeId:event.eventType !== null ? event.eventType.id : null,
            date: event.date,
            notes: event.notes,
            remainder: event.remainder,
            read: event.reminded,
            doneDateTime: event.doneDateTime,
            userIDs: event.users.map(u => u.id),
            eventStatusId: event.eventStatus ? event.eventStatus.id : null,
            resolution: event.resolution
        }

        if (_event.id) {
            const index = findIndexById(_event.id);
            eventService.setEvent(_event).then(data => {
                _events[index] = data;
                setEvents(_events);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Event Updated',
                    life: 3000
                });
            });
        } else {
            eventService.setEvent(_event).then(data => {
                _events.push(data);
                setEvents(_events);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Event Created',
                    life: 3000
                });
            });
        }


        if(type === 'new'){
            let newEvent = {
                id: null,
                client: event.client,
                contactPerson: event.contactPerson,
                eventType: event.eventType,
                date: null,
                notes: event.notes,
                remainder: event.remainder,
                read: false,
                doneDateTime: null,
                users: event.users,
                eventStatus: event.eventStatus,
                resolution: event.resolution
            }
            setEvent(newEvent)
        } else if(type === 'save'){
            setEvent(emptyEvent);
            setEventDialog(false);
        }
    }

    const deleteEvent = () => {
        eventService.deleteEvent(event.id).then(data => {
            let _events = events.filter(val => val.id !== event.id);
            setEvents(_events);
            setDeleteEventDialog(false);
            setEvent(emptyEvent);
            toast.current.show({
                severity: 'success',
                summary: 'Successful',
                detail: 'Event Was Deleted',
                life: 3000
            });
        });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _event = {...event};
        _event[`${name}`] = val;
        setEvent(_event);
    }

    const onDateChange = (e) => {
        let _event = {...event};
        _event.date = e.value;
        setEvent(_event);
    }

    const onRemainderChange = (e) => {
        let _event = {...event};
        _event.remainder = e.value;
        setEvent(_event);
    }

    const changeEventType = (e) => {
        let _event = {...event};
        let filteredEventType = eventsTypes.filter(ev => ev.id === e.value.id)
        _event.eventType = filteredEventType[0];
        setEvent(_event);
    }

    const changeEventContactPerson = (e) => {
        let _event = {...event};
        let filteredContactPerson = clientContactPersons.filter(ev => ev.id === e.value.id)
        _event.contactPerson = filteredContactPerson[0];
        setEvent(_event);
    }

    const changeEventStatus = (e) => {
        let _event = {...event};
        _event.eventStatus = e.value;
        setEvent(_event);
    }

    const changeEventManager = (e) => {
        let _event = {...event};
        _event.users = e.value;
        // setManagers(e.value)
        setEvent(_event);
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < events.length; i++) {
            if (events[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const tableHeader = (
        <div className="table-header">
            <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew}/>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const contactPersonBodyTemplate = (data, props) => {
        if(data.contactPerson === null)
            return

        let _contactPerson = typeof data.contactPerson === 'object' ? data.contactPerson : clientContactPersons.find(e => e.id === data.contactPerson);
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {_contactPerson.name + ' ' + _contactPerson.surname}
            </>
        );
    };

    const managerBodyTemplate = (data, props) => {
        let _users = data.users;
        return (
            <>
                {
                    _users.map(user => <p key={user.id} style={{
                        margin: 0,
                        lineHeight: 'inherit'
                    }}>{user.firstName} {user.lastName} </p>)
                }
            </>
        );
    };

    const eventTypeBodyTemplate = (data, props) => {
        let _eventType = typeof data.eventType === 'object' ? data.eventType : eventsTypes.find(e => e.id === data.eventType);
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {_eventType.title}
            </>
        );
    };

    const eventReadBodyTemplate = (data, props) => {
        let _eventType = typeof data.eventType === 'object' ? data.eventType : eventsTypes.find(e => e.id === data.eventType);
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {_eventType.title}
            </>
        );
    };

    const dateBodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {moment(data.date).format('YYYY-MM-DD kk:mm')}
            </>
        );
    };

    const actionTemplate = (rowData) => (<>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2 p-mb-2"
                onClick={() => editEvent(rowData)}/>
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-mr-2 p-mb-2"
                onClick={() => confirmDeleteEvent(rowData)}/>
    </>);

    const eventDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => checkRequired('save')}/>
            <Button label="Save & New" icon="pi pi-check-square" className="p-button-text" onClick={() => checkRequired('new')}/>
        </>
    );

    const deleteEventDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEventDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteEvent}/>
        </>
    );

    const contactPersonLabel = (e) => {
        return e.name + ' ' + e.surname
    }

    // const managerLabel = (e) => {
    //     return e.firstName + ' ' + e.lastName
    // }

    const selectedContactPersonTemplate = (option, props) => {
        if (props.value) {
            return (
                <div className="contact-person-item contact-person-item-value">
                    <div style={{color: 'rgba(0, 0, 0, 0.87)'}}>{props.value.name + ' ' + props.value.surname}</div>
                </div>
            );
        }

        return (
            <span>
            {props.placeholder}
        </span>
        );
    }

    const selectedManagerTemplate = (option, props) => {
        if (option) {
            return (
                <div className="manager-item manager-item-value">
                    {option.firstName + ' ' + option.lastName}
                </div>
            );
        }
    }

    const managerOptionTemplate = (option) => {
        return (
            <div className="manager-item">
                <div>{option.firstName + ' ' + option.lastName}</div>
            </div>
        );
    }

    const checkDisableEventStatus = () => {
        if(event.date !== null) {
            let now = moment(new Date());
            let end = moment(event.date);
            let duration = moment.duration(now.diff(end));
            let timeCheck = duration.asDays() >= -1;
            if (event.reminded && timeCheck) {
                return false
            }
        }
        return true
    }

    return (
        <div className="p-grid table-demo">
            <Toast ref={toast}/>
            <div className="p-col-12">
                <div className="card" style={{height: 'calc(100vh - 400px)'}}>
                <DataTable value={events} paginator rows={5}
                           dataKey="id" rowHover selection={selectedEvent}
                           className={'p-datatable-sm'}
                           scrollHeight={'fex'}
                           responsiveLayout={'scroll'}
                           scrollable={true}
                           onSelectionChange={(e) => setSelectedEvent(e.value)}
                           emptyMessage="No events found." loading={loading}
                           header={tableHeader}>
                    <Column field="read" header="Read" sortable body={eventReadBodyTemplate}/>
                    <Column field="type" header="Type" sortable body={eventTypeBodyTemplate}/>
                    <Column field="contactPerson" header="Contact Person" sortable body={contactPersonBodyTemplate}/>
                    <Column field="users" header="Manager" sortable body={managerBodyTemplate}/>
                    <Column field="date" header="Date" sortable body={dateBodyTemplate}/>
                    <Column field="notes" header="Note" sortable body={bodyTemplate}/>
                    <Column field="remainder" header="Remainder" sortable body={bodyTemplate}/>
                    <Column field="eventStatus.name" header="Status" sortable/>
                    <Column field="resolution" header="Resolution" sortable/>
                    <Column s body={actionTemplate} bodyStyle={{textAlign: 'right'}}/>
                </DataTable>
                </div>
            </div>
            <Dialog visible={eventDialog} style={{width: '450px'}} header="Event Details" modal
                    id={'eventDialog'} className="p-fluid" footer={eventDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="type">Type</label>
                    <Dropdown value={event.eventType} options={eventsTypes} optionLabel="title" required
                              placeholder="-- Select --"
                              className={classNames({'p-invalid': submitted && !event.eventType})}
                              disabled={event.reminded} onChange={changeEventType}/>
                    {submitted && !event.eventType &&
                    <small className="p-invalid">Type is required.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="contactPerson">Contact person</label>
                    <Dropdown name={'contactPerson'} value={event.contactPerson}
                              options={clientContactPersons} optionLabel="name"
                              placeholder="-- Select --" disabled={event.reminded}
                              itemTemplate={contactPersonLabel} onChange={changeEventContactPerson}
                              valueTemplate={selectedContactPersonTemplate}/>
                </div>
                <div className="p-field">
                    <label htmlFor="manager">Manager</label>
                    <MultiSelect value={event.users} onChange={changeEventManager}
                                 options={users} panelHeaderTemplate={null}
                                 optionLabel={'firstName'} placeholder="-- Select --"
                                 filter filterBy="firstName" disabled={event.reminded}
                                 selectedItemTemplate={selectedManagerTemplate}
                                 itemTemplate={managerOptionTemplate}
                                 className={classNames({'p-invalid': submitted && !event.users})}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="date">Date</label>
                    <Calendar id="date" value={event.date ? new Date(event.date) : null} onChange={onDateChange}
                              viewDate={todayCalendar(event.date)} ref={calendarRef}
                              onViewDateChange={(e) => onViewChange(e, calendarRef)} numberOfMonths={3}
                              placeholder="Select a date" locale={'lt'}
                              showTime showIcon required appendTo={document.body} dateFormat={'yy-mm-dd'}
                              disabled={event.reminded}
                              className={classNames({'p-invalid': submitted && !event.date})}/>
                </div>
                <div className="p-field">
                    <label htmlFor="notes">Notes</label>
                    <InputTextarea id="notes" value={event.notes} onChange={(e) => onInputChange(e, 'notes')}
                                   required autoFocus autoResize rows={5} cols={30} placeholder="Write a notes..."
                                   disabled={event.reminded} className={classNames({'p-invalid': submitted && !event.notes})}/>
                </div>
                <div className="p-field">
                    <label htmlFor="remainder">Remainder</label>
                    <InputNumber id="remainder" value={event.remainder} onChange={onRemainderChange} disabled={event.reminded}
                                 required autoFocus prefix="About Event get notification before " suffix=" minutes"
                                 className={classNames({'p-invalid': submitted && !event.remainder && event.remainder < 0})}/>
                </div>
                <div className="p-field">
                    <label htmlFor="eventStatus">Status</label>
                    <Dropdown name={'eventStatus'} value={event.eventStatus}
                              options={eventStatuses} optionLabel="name"
                              placeholder="-- Select --" disabled={checkDisableEventStatus()}
                              onChange={changeEventStatus} />
                </div>
                <div className="p-field">
                    <label htmlFor="resolution">Resolution</label>
                    <InputTextarea id="resolution" value={event.resolution ? event.resolution : ''}
                                   onChange={(e) => onInputChange(e, 'resolution')}
                                   required autoFocus autoResize rows={5} cols={30}
                                   placeholder="Write a resolution..." disabled={!event.reminded}/>
                </div>
            </Dialog>
            <Dialog visible={deleteEventDialog} style={{width: '450px'}} header="Confirm" modal
                    footer={deleteEventDialogFooter} onHide={hideDeleteEventDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                    {event && event.date &&
                    <span>Are you sure you want to delete event <b>{moment(event.date).format('YYYY-MM-DD kk:mm')}  </b>?</span>}
                </div>
            </Dialog>
        </div>
    )
}
